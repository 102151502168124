import { body } from './elements.js';
import { closest } from './helpers.js';

// Popups
export default function initPopups() {
	const popups = document.querySelectorAll('.js-popup');
	const popupTriggers = document.querySelectorAll('.js-popup-trigger');
	const popupCloses = document.querySelectorAll('.js-popup-close');
	const headers = document.querySelectorAll('.js-header-container');
	let cachedScrollPos;

	closest();

	function closePopupsWithin(scope) {
		let scopeEls;

		if (scope.nodeType) {
			return;
		} else {
			if (document.querySelector(scope) && document.querySelector(scope).nodeType) {
				scopeEls = document.querySelectorAll(scope);
			} else {
				return;
			}
		}

		for (let i = 0; i < scopeEls.length; i++) {
			const scopeEl = scopeEls[i];
			const popupsInContainer = scopeEl.querySelectorAll('.js-popup');

			for (let j = 0; j < popupsInContainer.length; j++) {
				const popupInContainer = popupsInContainer[j];

				if (popupInContainer.classList.contains('active')) {
					popupInContainer.classList.remove('active');
				} 
			}
		}
	}

	function closeNearPopups(near) {
		let nearEl;

		if (near) {
			nearEl = near;
		} else {
			return;
		}

		const popupsContainer = nearEl.closest('.js-popups');
		const triggersInContainer = popupsContainer.querySelectorAll('.js-popup-trigger');
		const popupsInContainer = popupsContainer.querySelectorAll('.js-popup');

		for (let i = 0; i < triggersInContainer.length; i++) {
			const triggerInContainer = triggersInContainer[i];

			triggerInContainer.classList.remove('active');
		}

		for (let i = 0; i < popupsInContainer.length; i++) {
			const menuInContainer = popupsInContainer[i];

			menuInContainer.classList.remove('active');
		}

		if (body.classList.contains('freeze')) {
			body.classList.remove('freeze'); 
			body.style.top = 'auto';

			if (!body.classList.contains('bar-hidden')) {
				if (body.getAttribute('data-top') !== 'false' && body.getAttribute('data-top') !== null) {
					window.scrollTo(0, body.getAttribute('data-top'));
					body.setAttribute('data-top', false);
				} else {
					window.scrollTo(0, cachedScrollPos);
				}
			}

			body.classList.remove('bar-hidden');

			for (let i = 0; i < headers.length; i++) {
				const header = headers[i];

				header.classList.remove('open');
			}
		}
	}

	function openPopup(popupTrigger, target) {
		const popupsContainer = popupTrigger.closest('.js-popups');
		const popupsInContainer = popupsContainer.querySelectorAll(target);

		cachedScrollPos = window.pageYOffset;
		body.classList.add('freeze');
		body.style.top = -cachedScrollPos + 'px';
		popupTrigger.classList.add('active');

		for (let j = 0; j < popupsInContainer.length; j++) {
			const popupInContainer = popupsInContainer[j];

			popupInContainer.classList.add('active');
		}

		for (let i = 0; i < headers.length; i++) {
			const header = headers[i];
			
			header.classList.add('open');
		}
	}

	function togglePopup(popupTrigger, target) {
		const popup = document.querySelector(target);

		if (!popup.classList.contains('active')) {
			closeNearPopups(popup);
			openPopup(popupTrigger, target);
		} else {
			closeNearPopups(popup);
		}
	}

	for (let i = 0; i < popupTriggers.length; i++) {
		const popupTrigger = popupTriggers[i];

		const target = popupTrigger.getAttribute('data-target');
		const popup = document.querySelectorAll(target);

		popupTrigger.addEventListener('click', function() {
			event.preventDefault();
			togglePopup(popupTrigger, target);
		});
	}

	for (let i = 0; i < popups.length; i++) {
		const popup = popups[i];

		window.addEventListener('resize', function() {
			if (window.innerWidth > 1199 && popup.classList.contains('active') && popup.classList.contains('js-popup-hidden-desktop')) {
				closeNearPopups(popup);
			}
		});

		window.addEventListener('resize', function() {
			if (window.innerWidth < 768 && popup.classList.contains('active') && popup.classList.contains('js-popup-hidden-mobile')) {
				closeNearPopups(popup);
			}
		});
	}
	
	for (let i = 0; i < popupCloses.length; i++) {
		const popupClose = popupCloses[i];

		popupClose.addEventListener('click', function() {
			event.preventDefault();
			closeNearPopups(popupClose);
		});
	}
}
