import { closest } from './helpers.js';

// Accordions
export default function initAccordions() {
	const accords = document.querySelectorAll('.js-accordion');

    closest();

    

	function slideUp(el, duration) {
		const cachedHeight = el.getAttribute('data-height');
        const currentHeight = 1000;

    
		if (
			!cachedHeight ||
			!cachedHeight == currentHeight
		) {
			el.setAttribute('data-height', currentHeight);
		}

		el.style.transition = duration/1000 + 's ease-out';
		el.style.maxHeight = 0;
	}

	function slideDown(el, duration) {
		const cachedHeight = el.getAttribute('data-height');

		el.style.transition = duration/1000 + 's ease-out';
		el.style.maxHeight = cachedHeight + 'px';
	}

	function closeAccordsWithin(scope, duration) {
		let scopeEls;

		if (scope.nodeType) {
			return;
		} else {
			if (document.querySelector(scope) && document.querySelector(scope).nodeType) {
				scopeEls = document.querySelectorAll(scope);
			} else {
				return;
			}
		}

		for (let i = 0; i < scopeEls.length; i++) {
			const scopeEl = scopeEls[i];
			const accordsInContainer = scopeEl.querySelectorAll('.js-accordion');

			for (let j = 0; j < accordsInContainer.length; j++) {
				const accordInContainer = accordsInContainer[j];
				const accordTriggerInContainer = accordInContainer.querySelector('.js-accordion-trigger');
				const accordExpandInContainer = accordInContainer.querySelector('.js-accordion-expand');

				if (accordInContainer.classList.contains('active')) {
					accordInContainer.classList.remove('active');
					accordTriggerInContainer.classList.remove('active');
					slideUp(accordExpandInContainer, duration);
				}
			}
		}
	}

	function closeNearAccords(near) {
		let nearEl;

		if (near) {
			nearEl = near;
		} else {
			return;
		}

		const accordsContainer = nearEl.closest('.js-accordions');
		const accordionsInContainer = accordsContainer.querySelectorAll('.js-accordion');
		const accordionTriggersInContainer = accordsContainer.querySelectorAll('.js-accordion-trigger');
		const accordionExpandsInContainer = accordsContainer.querySelectorAll('.js-accordion-expand');

		for (let i = 0; i < accordionsInContainer.length; i++) {
			const accordionInContainer = accordionsInContainer[i];

			accordionInContainer.classList.remove('active');
		}

		for (let i = 0; i < accordionTriggersInContainer.length; i++) {
			const accordionTriggerInContainer = accordionTriggersInContainer[i];

			accordionTriggerInContainer.classList.remove('active');
		}

		for (let i = 0; i < accordionExpandsInContainer.length; i++) {
			const accordionExpandInContainer = accordionExpandsInContainer[i];

			slideUp(accordionExpandInContainer, 300);
		}
	}

	for (let i = 0; i < accords.length; i++) {
		const accord = accords[i];
		const accordTrigger = accord.querySelector('.js-accordion-trigger');
		const accordExpand = accord.querySelector('.js-accordion-expand');

		if (
			!accord.classList.contains('active') &&
			!accord.classList.contains('js-accordion-solid')
		) {
			accord.classList.remove('active');
			accordTrigger.classList.remove('active');
			slideUp(accordExpand, 0);
		}
		// window.addEventListener('load', function() {
		// });

		accordTrigger.addEventListener('click', function(event) {
			const accordTrigger = this;
			const accordsContainer = accordTrigger.closest('.js-accordions');

			event.preventDefault();

			if (!accordTrigger.classList.contains('active')) {
				if (accordsContainer.classList.contains('js-accordions-single')) {
					closeNearAccords(accordTrigger);
				}

				accord.classList.add('active');
				accordTrigger.classList.add('active');
				slideDown(accordExpand, 300);
			} else {
				accord.classList.remove('active');
				accordTrigger.classList.remove('active');
				slideUp(accordExpand, 300);
			}
		});
	}

	window.addEventListener('resize', function() {
		if (window.innerWidth > 1023) {
			closeAccordsWithin('.js-accordions-tablet', 0);
		}
	});

	
}

/* VOYAGES Accordion */
export function initVoyagesAccordion() {

	$('.voyages .accordion .card-header .h3').click(function () {
		
        $('.card-body-collapse.show')
            .collapse('hide');
    });

}