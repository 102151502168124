import { header } from './elements.js';

// Add polyfill for closest  
function closest() {
	if (!Element.prototype.matches) {
		Element.prototype.matches = Element.prototype.msMatchesSelector || 
									Element.prototype.webkitMatchesSelector;
	}

	if (!Element.prototype.closest) {
		Element.prototype.closest = function(s) {
			let el = this;

			do {
				if (el.matches(s)) return el;
				el = el.parentElement || el.parentNode;
			} while (el !== null && el.nodeType === 1);

			return null;
		};
	}
}

// Add polyfill for after
function after() {
	(function (arr) {
		arr.forEach(function (item) {
			if (item.hasOwnProperty('after')) {
				return;
			}
			Object.defineProperty(item, 'after', {
				configurable: true,
				enumerable: true,
				writable: true,
				value: function after() {
					var argArr = Array.prototype.slice.call(arguments),
						docFrag = document.createDocumentFragment();
					
					argArr.forEach(function (argItem) {
						var isNode = argItem instanceof Node;
						docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
					});
					
					this.parentNode.insertBefore(docFrag, this.nextSibling);
				}
			});
		});
	})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
}

// Register events function
function registerEvents(func, events) {
	for (let i = 0; i < events.length; i++) {
		window.addEventListener(events[i], func, false);
	};
}

// Event creator
function createNewEvent(eventName) {
	let event;

	if (typeof(Event) === 'function') {
		event = new Event(eventName);
	} else {
		event = document.createEvent('Event');
		event.initEvent(eventName, true, true);
	}
	return event;
}

// Offset calculator
function calcOffsetTop(el) {
	const rect = el.getBoundingClientRect();
	let offsetResult;

	offsetResult = rect.top + window.pageYOffset;
	return offsetResult; 
}

// Offset calculator
function calcOffsetLeft(el) {
	const rect = el.getBoundingClientRect();
	let offsetResult;

	offsetResult = rect.left;
	return offsetResult; 
}

// Dynamic padding
function initDynamicPadding() {
	function updatePadding() {
		const headerHeight = header.offsetHeight;
		const dynamicPaddings = document.querySelectorAll('.js-dynamic-padding');
		const scroll = window.pageYOffset;

		if (
				!header.classList.contains('scrolled') || 
				!scroll > 0 && !header.classList.contains('open')
			) {
			for (let i = 0; i < dynamicPaddings.length; i++) {
				const dynamicPadding = dynamicPaddings[i];

				if (getComputedStyle(dynamicPadding).paddingTop !== headerHeight + 'px') {
					dynamicPadding.style.paddingTop = headerHeight + 'px';
				}
			}
		}
	}

	registerEvents(updatePadding, ['load', 'scroll', 'resize']);
}

function forceUpdateDynamicPadding() {
	function updatePadding() {
		const headerHeight = header.offsetHeight;
		const dynamicPaddings = document.querySelectorAll('.js-dynamic-padding');
		const scroll = window.pageYOffset;

		if (
				!header.classList.contains('scrolled')
			) {
			for (let i = 0; i < dynamicPaddings.length; i++) {
				const dynamicPadding = dynamicPaddings[i];

				if (getComputedStyle(dynamicPadding).paddingTop !== headerHeight + 'px') {
					dynamicPadding.style.paddingTop = headerHeight + 'px';
				}
			}
		}
	}

	updatePadding();
}

export { closest, after, registerEvents, createNewEvent, initDynamicPadding, forceUpdateDynamicPadding, calcOffsetTop, calcOffsetLeft };
