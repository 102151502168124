// 
// [Select] Go to page
// ----------------------------------------

export default function initSite() {
    $('.go-to-page').on('change',
        function() {
            var url = $(this).val();

            if (url) {
                window.location = url;
            }

            return false;
        });

    $(".scroll-link").click(function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top-200
            },
            2000);
    });
}