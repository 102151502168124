import { body } from './elements.js';
import { closest } from './helpers.js';
import { forceUpdateDynamicPadding } from './helpers.js';
import { forceUpdateShadows } from './shadows.js';


// Sticky header
export default function initBars() {
	const bars = document.querySelectorAll('.js-bar');
	let cachedScrollPos;

	closest();

	for (let i = 0; i < bars.length; i++) {
		const bar = bars[i];
		const barClose = bar.querySelector('.js-bar-close');

		barClose.addEventListener('click', function() {
			event.preventDefault();
			bar.classList.add('hidden');
			forceUpdateDynamicPadding();

			if (body.classList.contains('freeze')) {
				body.classList.add('bar-hidden');
				body.style.top = 0;
				forceUpdateShadows();
			}
		});
	}
}
