import { calcOffsetTop } from './helpers.js';

// Scroller
export default function initScrollers() {
	const scrollers = document.querySelectorAll('.js-scroller');

	for (let i = 0; i < scrollers.length; i++) {
		const scroller = scrollers[i];	
		const target = scroller.getAttribute('data-target');

		if (target == 'top') {
			const scrollOptionsTop = {
				left: 0,
				top: 0,
				behavior: 'smooth'
			}

			scroller.addEventListener('click', function(event) {
				event.preventDefault();
				window.scrollTo(scrollOptionsTop);
			});
		} else {
			const targetEl = document.querySelector(target);

			scroller.addEventListener('click', function(event) {
				const targetPos = calcOffsetTop(targetEl) + 1;
				const targetOffset = window.innerHeight / 8;
				const scrollOptionsEl = {
					left: 0,
					top: targetPos - targetOffset,
					behavior: 'smooth'
				}

				event.preventDefault();
				window.scrollTo(scrollOptionsEl);
			});
		}
	}
}
