import { closest } from './helpers.js';

// Sliders
export default function initSliders() {
	const swipers = document.querySelectorAll('.js-slider');
	
	closest();

	for (let i = 0; i < swipers.length; i++) {
		const swiper = swipers[i];	

		if (swiper.classList.contains('js-slider-fraction')) {
			new Swiper(swiper, {
				preloadImages: false,
				slidesPerView: 1,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				observer: true,
				loop: false,
				observeParents: true,
				observeSlideChildren: true,
				speed: 500,
				navigation: {
					nextEl: swiper.closest('.slider').querySelectorAll('.swiper-button-next'),
					prevEl: swiper.closest('.slider').querySelectorAll('.swiper-button-prev')
				},
				pagination: {
					el: swiper.closest('.slider').querySelectorAll('.swiper-pagination'),
					type: 'fraction',
					clickable: false,
					renderFraction: function (currentClass, totalClass) {
						  return '<span class="' + currentClass + '"></span>' +
								  ' of ' +
								  '<span class="' + totalClass + '"></span>';
					}
				}
			});
		} else if (swiper.classList.contains('js-slider-bar')) {
			new Swiper(swiper, {
				preloadImages: false,
				slidesPerView: 1,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				observer: true,
				loop: false,
				observeParents: true,
				observeSlideChildren: true,
				allowTouchMove: false,
				speed: 500,
				navigation: {
					nextEl: swiper.closest('.slider').querySelectorAll('.swiper-button-next'),
					prevEl: swiper.closest('.slider').querySelectorAll('.swiper-button-prev')
				},
				pagination: {
					el: swiper.closest('.slider').querySelectorAll('.swiper-pagination'),
					type: 'bullets',
					clickable: true
				},
			});

			if (swiper.swiper.slides.length > 1) {
				const swiperArrows = swiper.closest('.slider').querySelector('.slider-nav');

				swiperArrows.classList.remove('hidden');
				swiper.swiper.allowTouchMove = true;
			} else {
				swiper.closest('.slider').classList.add('bar--single');
			}
		} else {
			new Swiper(swiper, {
				preloadImages: false,
				slidesPerView: 1,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				observer: true,
				loop: false,
				observeParents: true,
				observeSlideChildren: true,
				allowTouchMove: true,
				speed: 500,
				navigation: {
					nextEl: swiper.closest('.slider').querySelectorAll('.swiper-button-next'),
					prevEl: swiper.closest('.slider').querySelectorAll('.swiper-button-prev')
				}
			});
		}
	}
}
