import { closest, registerEvents, calcOffsetLeft, calcOffsetTop } from './helpers.js';

// Shadows
function initShadows() {
	closest();

	function updateShadows() {
		const shadows = document.querySelectorAll('.js-shadow');

		for (let i = 0; i < shadows.length; i++) {
			const shadow = shadows[i];	

			const shadowSizer = shadow.closest('.js-shadow-sizer');
			const shadowHeader = shadow.closest('.js-header-container');
			const shadowHeaderOffset = calcOffsetTop(shadowHeader);

			if (!shadowHeader.classList.contains('fixed')) {
				if (getComputedStyle(shadow).top !== shadowHeaderOffset + 'px') {
					shadow.style.top = shadowHeaderOffset + 'px';
				}
			}

			if (getComputedStyle(shadow).position !== 'fixed') {
				shadow.style.position = 'fixed';
			}

			if (getComputedStyle(shadow).left !== calcOffsetLeft(shadowSizer) + 'px' ) {
				shadow.style.left = calcOffsetLeft(shadowSizer) + 'px';
			}

			if (getComputedStyle(shadow).width !== shadowSizer.offsetWidth + 'px') {
				shadow.style.width = shadowSizer.offsetWidth + 'px';
			}
		}
	}

	registerEvents(updateShadows, ['resize']);
}

function forceUpdateShadows() {
	function updateShadows() {
		const shadows = document.querySelectorAll('.js-shadow');

		for (let i = 0; i < shadows.length; i++) {
			const shadow = shadows[i];	

			const shadowSizer = shadow.closest('.js-shadow-sizer');
			const shadowHeader = shadow.closest('.js-header-container');
			const shadowHeaderOffset = calcOffsetTop(shadowHeader);

			if (!shadowHeader.classList.contains('fixed')) {
				if (getComputedStyle(shadow).top !== shadowHeaderOffset + 'px') {
					shadow.style.top = shadowHeaderOffset + 'px';
				}
			}

			if (getComputedStyle(shadow).position !== 'fixed') {
				shadow.style.position = 'fixed';
			}

			if (getComputedStyle(shadow).left !== calcOffsetLeft(shadowSizer) + 'px' ) {
				shadow.style.left = calcOffsetLeft(shadowSizer) + 'px';
			}

			if (getComputedStyle(shadow).width !== shadowSizer.offsetWidth + 'px') {
				shadow.style.width = shadowSizer.offsetWidth + 'px';
			}
		}
	}

	updateShadows();
}

export { initShadows, forceUpdateShadows };