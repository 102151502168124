import { body } from './elements.js';
import { closest, calcOffsetTop } from './helpers.js';
import { forceUpdateShadows } from './shadows.js';

// Mobile menu
export default function initMenus() {
	const menus = document.querySelectorAll('.js-menu');
	const menuTriggers = document.querySelectorAll('.js-menu-trigger');
	const menuCloses = document.querySelectorAll('.js-menu-close');
	const headers = document.querySelectorAll('.js-header-container');
	let cachedScrollPos;

	closest();

	function closeNearMenus(near) {
		let nearEl;

		if (near) {
			nearEl = near;
		} else {
			return;
		}

		const menusContainer = nearEl.closest('.js-menus');
		const triggersInContainer = menusContainer.querySelectorAll('.js-menu-trigger');
		const menusInContainer = menusContainer.querySelectorAll('.js-menu');

		for (let i = 0; i < triggersInContainer.length; i++) {
			const triggerInContainer = triggersInContainer[i];

			triggerInContainer.classList.remove('active');
		}

		for (let i = 0; i < menusInContainer.length; i++) {
			const menuInContainer = menusInContainer[i];

			menuInContainer.classList.remove('active');
		}

		if (body.classList.contains('freeze')) {
			body.classList.remove('freeze'); 
			body.style.top = 'auto';

			if (!body.classList.contains('bar-hidden')) {
				window.scrollTo(0, cachedScrollPos);
			}

			body.classList.remove('bar-hidden');

			for (let i = 0; i < headers.length; i++) {
				const header = headers[i];

				header.classList.remove('open');
			}
		}
	}

	function openMenu(menuTrigger, target) {
		const menusContainer = menuTrigger.closest('.js-menus');
		const menusInContainer = menusContainer.querySelectorAll(target);

		cachedScrollPos = window.pageYOffset;
		body.classList.add('freeze');
		body.style.top = -cachedScrollPos + 'px';
		menuTrigger.classList.add('active');
		forceUpdateShadows();

		for (let j = 0; j < menusInContainer.length; j++) {
			const menuInContainer = menusInContainer[j];

			const menuInContainerHeader = menuInContainer.closest('.js-header-container');
			const menuInContainerHeaderOffset = calcOffsetTop(menuInContainerHeader);

			menuInContainer.classList.add('active');

			if (!menuInContainerHeader.classList.contains('fixed')) {
				if (getComputedStyle(menuInContainer).height !== menuInContainerHeaderOffset + window.innerHeight + 'px') {
					menuInContainer.style.height = -menuInContainerHeaderOffset + window.innerHeight + 'px';
				}
			}
		}

		for (let i = 0; i < headers.length; i++) {
			const header = headers[i];
			
			header.classList.add('open');
		}
	}

	function toggleMenu(menuTrigger, target) {
		const menu = document.querySelector(target);

		if (!menu.classList.contains('active')) {
			closeNearMenus(menu);
			openMenu(menuTrigger, target);
		} else {
			closeNearMenus(menu);
		}
	}

	for (let i = 0; i < menuTriggers.length; i++) {
		const menuTrigger = menuTriggers[i];

		const target = menuTrigger.getAttribute('data-target');

		menuTrigger.addEventListener('click', function() {
			event.preventDefault();
			toggleMenu(menuTrigger, target);
		});

	}
	
	for (let i = 0; i < menus.length; i++) {
		const menu = menus[i];

		window.addEventListener('resize', function() {
			if (window.innerWidth > 1199 && menu.classList.contains('active') && menu.classList.contains('js-menu-hidden-desktop')) {
				closeNearMenus(menu);
			}
		});

		window.addEventListener('resize', function() {
			if (window.innerWidth < 1199 && menu.classList.contains('active') && menu.classList.contains('js-menu-hidden-smalldesktop')) {
				closeNearMenus(menu);
			}
		});

		window.addEventListener('resize', function() {
			if (window.innerWidth < 768 && menu.classList.contains('active') && menu.classList.contains('js-menu-hidden-mobile')) {
				closeNearMenus(menu);
			}
		});
	}

	for (let i = 0; i < menuCloses.length; i++) {
		const menuClose = menuCloses[i];

		menuClose.addEventListener('click', function() {
			event.preventDefault();
			closeNearMenus(menuClose);
		});
	}
}
