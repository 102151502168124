import 'bootstrap';
// import moment from 'moment';
// import Pikaday from 'pikaday';
import { initDynamicPadding } from './helpers.js';
import { initStickyHeader } from './stickyheader.js';
import initMenus from './menus.js';
import initPopups from './popups.js';
import { initShadows } from './shadows.js';
import { initAccordions, initVoyagesAccordion} from './accordions.js';
import initTabs from './tabs.js';
import initSliders from './sliders.js';
import initScrollers from './scrollers.js';
import initVideos from './videos.js';
import initBars from './bars.js';
import initMaps from './maps.js';
import { closest } from './helpers.js';
import initLogin from './login.js';
import initSite from './site.js';
import initCookieConsent from './cookieconsent.js';
import fetch from 'cross-fetch';


;(function(window, document, $) {
	initDynamicPadding();
	initStickyHeader();
	window.addEventListener('load', function() {
		// initMaps();
		initMenus();
		// initPopups();
	});
	initShadows();
	//initAccordions();
	initVoyagesAccordion();
	initTabs();
	initSliders();
	initScrollers();
	initVideos();
    initBars();
    initLogin();
    initSite();
    initCookieConsent();

	// const initBookingDatepicker = () => {
	// 	const format = 'DD/MM/YYYY';
	// 	const $datepickers = $('.js-datepicker-field');
	// 	let isKeyboard = false;

	// 	$datepickers.each((index, el) => {
	// 		const $datepicker = $(el);
	// 		const field = el.querySelector('.datepickerfield');
	// 		const fieldContainer = field.parentNode;

	// 		const picker = new Pikaday({
	// 			field: field,
	// 			container: fieldContainer,
	// 			minDate: new Date(),
	// 			numberOfMonths: 1,
	// 			format,
	// 			firstDay: 1,
	// 			showDaysInNextAndPreviousMonths: true,
	// 			enableSelectionDaysInNextAndPreviousMonths: true,
	// 			i18n: {
	// 				previousMonth : 'Previous Month',
	// 				nextMonth     : 'Next Month',
	// 				months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
	// 				weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
	// 				weekdaysShort : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
	// 			}
	// 		});
	// 	});
	// }

	// initBookingDatepicker();

	const vueRoutes = new Vue({
		el: '#routes',
		data: {
			selectedTab: 1,
			tabsData: []
		},
		mounted: function() {
            fetch('/umbraco/api/MapRoutes/GetAllRoutes')
				.then(response => response.json())
				.then((data) => this.tabsData = data)
                .then(() => {
        
					this.initMap();

					this.$nextTick(() => {
						this.initAcc();
						this.initPopups();
					});
				});

			this.initTooltip();
		},
		methods: {
			initTooltip() {
				$('[data-toggle="tooltip"]').tooltip();
			},
			initMap() {
				initMaps();
			},
			initAcc() {
				function closest() {
					if (!Element.prototype.matches) {
						Element.prototype.matches = Element.prototype.msMatchesSelector ||
													Element.prototype.webkitMatchesSelector;
					}

					if (!Element.prototype.closest) {
						Element.prototype.closest = function(s) {
							let el = this;

							do {
								if (el.matches(s)) return el;
								el = el.parentElement || el.parentNode;
							} while (el !== null && el.nodeType === 1);

							return null;
						};
					}
				}

				const accords = document.querySelectorAll('.js-accordion');

				closest();

				function slideUp(el, duration) {
					const cachedHeight = el.getAttribute('data-height');
                    const currentHeight = 1000;//el.offsetHeight + 30;
                   

					var new_hiehgt = el.querySelector('.article');

					console.log(el);
					console.log(" el.offsetHeight: " +  el.offsetHeight);
					console.log(" currentHeight: " +  currentHeight);

					if (
						!cachedHeight ||
						!cachedHeight == currentHeight
					) {
						el.setAttribute('data-height', currentHeight);
					}

					el.style.transition = duration/1000 + 's ease-out';
					el.style.maxHeight = 0;
				}

				function slideDown(el, duration) {
					const cachedHeight = el.getAttribute('data-height');

					el.style.transition = duration/1000 + 's ease-out';
					el.style.maxHeight = cachedHeight + 'px';
				}

				function closeAccordsWithin(scope, duration) {
					let scopeEls;

					if (scope.nodeType) {
						return;
					} else {
						if (document.querySelector(scope) && document.querySelector(scope).nodeType) {
							scopeEls = document.querySelectorAll(scope);
						} else {
							return;
						}
					}

					for (let i = 0; i < scopeEls.length; i++) {
						const scopeEl = scopeEls[i];
						const accordsInContainer = scopeEl.querySelectorAll('.js-accordion');

						for (let j = 0; j < accordsInContainer.length; j++) {
							const accordInContainer = accordsInContainer[j];
							const accordTriggerInContainer = accordInContainer.querySelector('.js-accordion-trigger');
							const accordExpandInContainer = accordInContainer.querySelector('.js-accordion-expand');

							if (accordInContainer.classList.contains('active')) {
								accordInContainer.classList.remove('active');
								accordTriggerInContainer.classList.remove('active');
								slideUp(accordExpandInContainer, duration);
							}
						}
					}
				}

				function closeNearAccords(near) {
					let nearEl;

					if (near) {
						nearEl = near;
					} else {
						return;
					}

					const accordsContainer = nearEl.closest('.js-accordions');
					const accordionsInContainer = accordsContainer.querySelectorAll('.js-accordion');
					const accordionTriggersInContainer = accordsContainer.querySelectorAll('.js-accordion-trigger');
					const accordionExpandsInContainer = accordsContainer.querySelectorAll('.js-accordion-expand');

					for (let i = 0; i < accordionsInContainer.length; i++) {
						const accordionInContainer = accordionsInContainer[i];

						accordionInContainer.classList.remove('active');
					}

					for (let i = 0; i < accordionTriggersInContainer.length; i++) {
						const accordionTriggerInContainer = accordionTriggersInContainer[i];

						accordionTriggerInContainer.classList.remove('active');
					}

					for (let i = 0; i < accordionExpandsInContainer.length; i++) {
						const accordionExpandInContainer = accordionExpandsInContainer[i];

						slideUp(accordionExpandInContainer, 300);
					}
				}

				for (let i = 0; i < accords.length; i++) {
					const accord = accords[i];
					const accordTrigger = accord.querySelector('.js-accordion-trigger');
					const accordExpand = accord.querySelector('.js-accordion-expand');

					if (
						!accord.classList.contains('active') &&
						!accord.classList.contains('js-accordion-solid')
					) {
						accord.classList.remove('active');
						accordTrigger.classList.remove('active');
						slideUp(accordExpand, 0);
					}
					

					accordTrigger.addEventListener('click', function(event) {
						const accordTrigger = this;
						const accordsContainer = accordTrigger.closest('.js-accordions');

						event.preventDefault();

						if (!accordTrigger.classList.contains('active')) {
							if (accordsContainer.classList.contains('js-accordions-single')) {
								closeNearAccords(accordTrigger);
							}

							accord.classList.add('active');
							accordTrigger.classList.add('active');
							slideDown(accordExpand, 300);
						} else {
							accord.classList.remove('active');
							accordTrigger.classList.remove('active');
							slideUp(accordExpand, 300);
						}
					});
				}

				if (window.innerWidth > 1023) {
					closeAccordsWithin('.js-accordions-tablet', 0);
				}
			},
			initPopups() {
				const body = document.querySelector('body');
				const popups = document.querySelectorAll('.js-popup');
				const popupTriggers = document.querySelectorAll('.js-popup-trigger');
				const popupCloses = document.querySelectorAll('.js-popup-close');
				const headers = document.querySelectorAll('.js-header-container');
				let cachedScrollPos;

				closest();

				function closest() {
					if (!Element.prototype.matches) {
						Element.prototype.matches = Element.prototype.msMatchesSelector ||
													Element.prototype.webkitMatchesSelector;
					}

					if (!Element.prototype.closest) {
						Element.prototype.closest = function(s) {
							let el = this;

							do {
								if (el.matches(s)) return el;
								el = el.parentElement || el.parentNode;
							} while (el !== null && el.nodeType === 1);

							return null;
						};
					}
				}

				function closePopupsWithin(scope) {
					let scopeEls;

					if (scope.nodeType) {
						return;
					} else {
						if (document.querySelector(scope) && document.querySelector(scope).nodeType) {
							scopeEls = document.querySelectorAll(scope);
						} else {
							return;
						}
					}

					for (let i = 0; i < scopeEls.length; i++) {
						const scopeEl = scopeEls[i];
						const popupsInContainer = scopeEl.querySelectorAll('.js-popup');

						for (let j = 0; j < popupsInContainer.length; j++) {
							const popupInContainer = popupsInContainer[j];

							if (popupInContainer.classList.contains('active')) {
								popupInContainer.classList.remove('active');
							}
						}
					}
				}

				function closeNearPopups(near) {
					let nearEl;

					if (near) {
						nearEl = near;
					} else {
						return;
					}

					const popupsContainer = nearEl.closest('.js-popups');
					const triggersInContainer = popupsContainer.querySelectorAll('.js-popup-trigger');
					const popupsInContainer = popupsContainer.querySelectorAll('.js-popup');

					for (let i = 0; i < triggersInContainer.length; i++) {
						const triggerInContainer = triggersInContainer[i];

						triggerInContainer.classList.remove('active');
					}

					for (let i = 0; i < popupsInContainer.length; i++) {
						const menuInContainer = popupsInContainer[i];

						menuInContainer.classList.remove('active');
					}

					if (body.classList.contains('freeze')) {
						body.classList.remove('freeze');
						body.style.top = 'auto';

						if (!body.classList.contains('bar-hidden')) {
							if (body.getAttribute('data-top') !== 'false' && body.getAttribute('data-top') !== null) {
								window.scrollTo(0, body.getAttribute('data-top'));
								body.setAttribute('data-top', false);
							} else {
								window.scrollTo(0, cachedScrollPos);
							}
						}

						body.classList.remove('bar-hidden');

						for (let i = 0; i < headers.length; i++) {
							const header = headers[i];

							header.classList.remove('open');
						}
					}
				}

				function openPopup(popupTrigger, target) {
					const popupsContainer = popupTrigger.closest('.js-popups');
					const popupsInContainer = popupsContainer.querySelectorAll(target);

					cachedScrollPos = window.pageYOffset;
					body.classList.add('freeze');
					body.style.top = -cachedScrollPos + 'px';
					popupTrigger.classList.add('active');

					for (let j = 0; j < popupsInContainer.length; j++) {
						const popupInContainer = popupsInContainer[j];

						popupInContainer.classList.add('active');
					}

					for (let i = 0; i < headers.length; i++) {
						const header = headers[i];

						header.classList.add('open');
					}
				}

				function togglePopup(popupTrigger, target) {
					const popup = document.querySelector(target);

					if (!popup.classList.contains('active')) {
						closeNearPopups(popup);
						openPopup(popupTrigger, target);
					} else {
						closeNearPopups(popup);
					}
				}

				for (let i = 0; i < popupTriggers.length; i++) {
					const popupTrigger = popupTriggers[i];

					const target = popupTrigger.getAttribute('data-target');
					const popup = document.querySelectorAll(target);

					popupTrigger.addEventListener('click', function() {
						event.preventDefault();
						togglePopup(popupTrigger, target);
					});
				}

				for (let i = 0; i < popups.length; i++) {
					const popup = popups[i];

					if (window.innerWidth > 1199 && popup.classList.contains('active') && popup.classList.contains('js-popup-hidden-desktop')) {
						closeNearPopups(popup);
					}

					if (window.innerWidth < 768 && popup.classList.contains('active') && popup.classList.contains('js-popup-hidden-mobile')) {
						closeNearPopups(popup);
					}
				}

				for (let i = 0; i < popupCloses.length; i++) {
					const popupClose = popupCloses[i];

					popupClose.addEventListener('click', function() {
						event.preventDefault();
						closeNearPopups(popupClose);
					});
				}
			}
		}
	});
})(window, document, window.jQuery);
