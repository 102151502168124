import { closest } from './helpers.js';

// Tab remote
export default function initTabs() {
	const tabRemotes = document.querySelectorAll('.js-tab-remote');

	closest();

	function closeTabsWithin(scope) {
		let scopeEls;

		if (scope.nodeType) {
			return;
		} else {
			if (document.querySelector(scope) && document.querySelector(scope).nodeType) {
				scopeEls = document.querySelectorAll(scope);
			} else {
				return;
			}
		}

		for (let i = 0; i < scopeEls.length; i++) {
			const scopeEl = scopeEls[i];
			const remotesInContainer = scopeEl.querySelectorAll('.js-tab-remote');
			const tabsInContainer = scopeEl.querySelectorAll('.js-tab');

			for (let j = 0; j < remotesInContainer.length; j++) {
				const remoteInContainer = remotesInContainer[j];

				remoteInContainer.classList.remove('active');
			}

			for (let j = 0; j < tabsInContainer.length; j++) {
				const tabInContainer = tabsInContainer[j];

				tabInContainer.classList.remove('active');
			}
		}
	}

	function closeNearTabs(near) {
		let nearEl;

		if (near) {
			nearEl = near;
		} else {
			return;
		}

		const tabsContainer = nearEl.closest('.js-tabs');
		const remotesInContainer = tabsContainer.querySelectorAll('.js-tab-remote');
		const tabsInContainer = tabsContainer.querySelectorAll('.js-tab');

		for (let i = 0; i < remotesInContainer.length; i++) {
			const remoteInContainer = remotesInContainer[i];

			if (!remoteInContainer.classList.contains('js-tab-remote-embed')) {
				remoteInContainer.classList.remove('active');
			}

			if (tabsContainer.classList.contains('js-tabs-embed')) {
				remoteInContainer.classList.remove('active');
			}
		}

		for (let i = 0; i < tabsInContainer.length; i++) {
			const tabInContainer = tabsInContainer[i];

			if (!tabInContainer.classList.contains('js-tab-embed')) {
				tabInContainer.classList.remove('active');
			}

			if (tabsContainer.classList.contains('js-tabs-embed')) {
				tabInContainer.classList.remove('active');
			}
		}
	}

	for (let i = 0; i < tabRemotes.length; i++) {
		const tabRemote = tabRemotes[i];	
		const target = tabRemote.getAttribute('data-target');
		const targetEls = tabRemote.closest('.js-tabs').querySelectorAll(target);

		window.addEventListener('load', function() {
			for (let j = 0; j < targetEls.length; j++) {
				const targetEl = targetEls[j];

				if (!tabRemote.classList.contains('active')) {
					if (!targetEl.classList.contains('js-tab-solid')) {
						targetEl.classList.remove('active');
					}
				}
			}

			if (tabRemote.closest('.js-tabs').classList.contains('js-tabs-accordions')) {
				const tabsContainerAccordions = tabRemote.closest('.js-tabs-accordions');
				const tabsAccordions = tabsContainerAccordions.querySelectorAll('.js-tab-accordions');

				for (let j = 0; j < tabsAccordions.length; j++) {
					const tabAccordions = tabsAccordions[j];

					tabAccordions.classList.remove('active');
					tabAccordions.classList.remove('fade');
				}
				
				tabsAccordions[0].classList.add('active');
			}
		});

		tabRemote.addEventListener('click', function(event) {
			const tabRemote = this;
			const tabsContainer = tabRemote.closest('.js-tabs');
			const target = tabRemote.getAttribute('data-target');
			const targetEls = tabsContainer.querySelectorAll(target);
			const target2 = tabRemote.getAttribute('data-target2');
			const targetEls2 = document.querySelectorAll(target2);

			event.preventDefault();

			if (!tabRemote.classList.contains('active')) {
				for (let j = 0; j < targetEls.length; j++) {
					const targetEl = targetEls[j];

					if (!tabsContainer.classList.contains('js-tabs-multiple')) {
						closeNearTabs(targetEl);
					}

					tabRemote.classList.add('active');
					targetEl.classList.add('active');
				}

				for (let j = 0; j < targetEls2.length; j++) {
					const targetEl2 = targetEls2[j];

					closeNearTabs(targetEl2);

					targetEl2.classList.add('active');
				}
			} else {
				if (!tabRemote.classList.contains('js-tab-remote-solid')) {
					closeNearTabs(targetEl);
				}
			}
		});
	}

	window.addEventListener('resize', function() {
		if (window.innerWidth < 1024) {
			closeTabsWithin('.js-tabs-desktop');
		}
	});
}
