import { body, header } from './elements.js';
import { after, registerEvents, calcOffsetTop } from './helpers.js';

// Sticky header
function initStickyHeader() {
	const stickyElements = document.querySelectorAll('.js-sticky-element');

	after();

	for (let i = 0; i < stickyElements.length; i++) {
		const stickyElement = stickyElements[i];

		const clonedElement = stickyElement.cloneNode(true);
		const clonedTriggers =  clonedElement.querySelectorAll('.js-sticky-trigger');
		const conedIds = clonedElement.querySelectorAll('[id]');
		const conedFors = clonedElement.querySelectorAll('[for]');

		stickyElement.after(clonedElement);
		clonedElement.classList.add('cloned');
		clonedElement.classList.add('fixed');

		for (let j = 0; j < clonedTriggers.length; j++) {
			const clonedTrigger = clonedTriggers[j];

			clonedTrigger.classList.remove('js-sticky-trigger');
		}

		for (let j = 0; j < conedIds.length; j++) {
			const conedId = conedIds[j];

			conedId.setAttribute('id', conedId.getAttribute('id') + '-cloned');
		}

		for (let j = 0; j < conedFors.length; j++) {
			const conedFor = conedFors[j];

			conedFor.setAttribute('for', conedFor.getAttribute('for') + '-cloned');
		}
	}

	function updateStickyHeader() {
		const scroll = window.pageYOffset;
		const stickyClone = document.querySelector('.js-sticky-element.cloned');
		const stickyHeight = stickyClone.offsetHeight;
		const stickyElements = document.querySelectorAll('.js-sticky-element');
		const stickyTriggers = document.querySelectorAll('.js-sticky-trigger');
		const stickyPaddings = document.querySelectorAll('.js-sticky-padding');

		for (let i = 0; i < stickyTriggers.length; i++) {
			const stickyTrigger = stickyTriggers[i];
			const stickyTriggerOffset = calcOffsetTop(stickyTrigger);

			for (let j = 0; j < stickyElements.length; j++) {
				const stickyElement = stickyElements[j];

				if (!body.classList.contains('freeze')) {
					if (scroll >= stickyTriggerOffset) {
						stickyElement.classList.add('scrolled');

						for (let k = 0; k < stickyPaddings.length; k++) {
							const stickyPadding = stickyPaddings[k];

							if (getComputedStyle(stickyPadding).paddingTop !== stickyHeight + 'px') {
								stickyPadding.style.paddingTop = stickyHeight + 'px';
							}
						}
					} else {
						stickyElement.classList.remove('scrolled');
					}
				}
			}
		}
	}

	registerEvents(updateStickyHeader, ['load', 'scroll', 'resize']);
}

export { initStickyHeader };