import { body } from './elements.js';
import { closest } from './helpers.js';

// Maps
export default function initMaps() {
	let globalInfoWindow, hasMarkers = false;
	const popupTriggersMap = document.querySelectorAll('.js-popup-trigger-map');
	const headers = document.querySelectorAll('.js-header-container');
	let cachedScrollPos;

	closest();

	function openPopup(target) {
		const popups = document.querySelectorAll(target);

		cachedScrollPos = window.pageYOffset;
		body.classList.add('freeze');
		body.style.top = -cachedScrollPos + 'px';
		body.setAttribute('data-top', cachedScrollPos);

		for (let j = 0; j < popups.length; j++) {
			const popup = popups[j];

			popup.classList.add('active');
		}

		for (let i = 0; i < headers.length; i++) {
			const header = headers[i];

			header.classList.add('open');
		}
	}

	function togglePopup(target) {
		const popup = document.querySelector(target);

		if (!popup.classList.contains('active')) {
			openPopup(target);
		}
	}

    function initMap(mapEl) {
        

        const mapContainer = $('.js-map');

    

		const hasClusters = mapContainer.attr('data-cluster');
        const infoboxes = $('.js-infobox');

        
		const pinPath = mapContainer.attr('data-pin');
        const pinWidth = mapContainer.attr('data-pin-width');
        const pinHeight = mapContainer.attr('data-pin-height');
		let map;
		let markerClustererContainer;
		let selectedInfoWindow;
		let infoContent;
		window.markers = [];

		let mapOptions = {
			zoom: 4,
			repeat: 0,
            center: { lat: 32.813132, lng: -81.660256 },
			scaleControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			mapTypeControl: false,
			overviewMapControl: false,
			panControl: false,
			rotateControl: false,
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.TOP_RIGHT
			},
			styles:  [
                {
                    "featureType": "administrative.country",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "color": "#afafaf"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#d3d8dd"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "landscape.natural",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.natural",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#d3d8dd"
						}
					]
				},
				{
					"featureType": "landscape.natural",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#d3d8dd"
						}
					]
				},
				{
					"featureType": "landscape.natural",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "landscape.natural.landcover",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#d3d8dd"
						}
					]
				},
				{
					"featureType": "landscape.natural.terrain",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "landscape.natural.terrain",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#fafafa"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				}
			]
		}

		mapEl.addEventListener('clearAllMarkers', function(){
			let nbMarkers = window.markers.length;
			for(let i=0; i< nbMarkers; i++){
				window.markers[i].setMap(null);
				window.markers[i] = null;
			}
			window.markers = [];
			if (markerClustererContainer) markerClustererContainer.clearMarkers();
		})

		if (mapEl.getAttribute('data-mapObject')) {
			map = mapEl.getAttribute('data-mapObject');
			if (isFiltered) {
				if(globalInfoWindow) {
					globalInfoWindow.close();
					globalInfoWindow = null;
				}
				if (hasMarkers) {
					$mapEl.trigger('clearAllMarkers');
				}
			}
		} else {
			map = new google.maps.Map(mapEl, mapOptions);
			mapEl.setAttribute('data-mapObject', map);
		}

        for (let i = 0; i < infoboxes.length; i++) {
        
			const infobox = infoboxes[i];
			const category = infobox.getAttribute('data-category');
			const lat = infobox.getAttribute('data-lat');
			const lng = infobox.getAttribute('data-lng');
			const position = new google.maps.LatLng(lat, lng);
			const title = infobox.getAttribute('data-title');
			const target = infobox.getAttribute('data-target');


			const icon = {
				url: pinPath,
				size: new google.maps.Size(10, 10),
				scaledSize: new google.maps.Size(10, 10),
				origin: new google.maps.Point(0, 0)
			}

			const marker = new google.maps.Marker({
				position,
				map,
				icon: icon,
				title,
				class: 'js-popup-trigger',
				target,
				category: category
			});

			infoContent = infobox.innerHTML;

			const infoWindow = new InfoBox({
				boxClass: 'infobox' + ' ' + 'init',
				content: infoContent,
				alignBottom: true,
				closeBoxURL: '',
				enableEventPropagation: true
			});

			window.markers.push(marker);
			hasMarkers = true;

			marker.addListener('click', function() {
				if (
					selectedInfoWindow != null &&
					selectedInfoWindow.getMap() != null
				) {
					selectedInfoWindow.close();
				} else if (selectedInfoWindow == infoWindow) {
					selectedInfoWindow = null;
                }
                
                map.setCenter(position);

                //var currentZoom = parseInt(map.zoom);

                //map.setZoom((currentZoom > 8 ? currentZoom : 8));

				selectedInfoWindow = infoWindow;
				globalInfoWindow = selectedInfoWindow;
				selectedInfoWindow.open(map, marker);

				setTimeout(function() {
					const infoBoxEl = selectedInfoWindow.div_;
					const infoboxLink = infoBoxEl.querySelector('.js-popup-trigger');

					infoBoxEl.addEventListener('click', function(event) {
						event.stopPropagation();
					});

					infoboxLink.addEventListener('click', function(event) {
						event.stopPropagation();

						togglePopup(marker.target);
					});
				}, 500);
			});

			map.addListener('click', function() {
				if(selectedInfoWindow) selectedInfoWindow.close();
			});

			google.maps.event.addListener(marker, 'click', function() {
				infoWindow.open(map, this);
			});
		}

		if ( hasClusters ) {
			const clusterImage = mapContainer.attr('data-cluster-image');
			const maxZoom = parseInt(mapContainer.attr('data-cluster-max-zoom'),10) || 9;

			markerClustererContainer = new MarkerClusterer(map, markers, {
				styles: [
					{
						url: clusterImage,
						width: 50,
						height: 50,
						backgroundPosition: '50% 50%',
						textColor: '#fff'
					}
				],
				gridSize: 15,
				zoomOnClick: true,
				maxZoom: maxZoom
			});

			google.maps.event.addListener(markerClustererContainer, 'clusterclick', function(cluster) {
				setTimeout(function() {
					if (cluster.map_.zoom > 17) {
						map.setZoom(17);
					}
				}, 10);
			});
		}

        function setFilter() {

 

            const clusterImage = mapContainer.attr('data-cluster-image');
            const maxZoom = parseInt(mapContainer.attr('data-cluster-max-zoom'), 10) || 9;

            //Clear all.
            markerClustererContainer.clearMarkers();

     

            const $this = $(this);

            var categories = [];

            $(".map-filter:checked").each(function () {
                categories.push($(this).attr('data-filter') + "");
            });

 

            let newMarkers = [];

            if (categories.length > 0) {
                         

                for (var i = 0; i < window.markers.length; i++) {

                 

                    const currentMarker = window.markers[i];
                    if (currentMarker.category != null) {
                        var cats = currentMarker.category.split(",");
                        var triggered = false;
                        for (var x = 0; cats.length > x; x++) {
                            
                            if (categories.indexOf(cats[x]) > -1 && !triggered) {
                                triggered = true;
                                newMarkers.push(currentMarker);
                                addMarkersToCluster(newMarkers);
                              
                            }
                        }
                    }

                   
                   
                };


            }
           
        };

        setFilter();

        $('.map-filter').on('change', function (event) {
            
			event.preventDefault();
            setFilter();

		
		});

		function addMarkersToCluster(updatedMarkers) {
			markerClustererContainer.clearMarkers();
			markerClustererContainer.addMarkers(updatedMarkers);
		}
	}

	const map1 = document.getElementById('map1');

    if (document.getElementById('map1')) {
        if ($(".infoboxes").length > 0) {
            var interval = window.setInterval(function () {
                if ($(".infoboxes .js-infobox").length > 0) {
                    clearInterval(interval);
                    initMap(document.getElementById('map1'));
                }
            
            });
        }

		
	}
}
