// Video
export default function initVideos() {
	const videos = document.querySelectorAll('.js-video');

	for (let i = 0; i < videos.length; i++) {
		const video = videos[i];

		const videoIframe = video.querySelector('iframe');
		const videoTrigger = video.querySelector('.js-video-trigger');

		videoTrigger.addEventListener('click', function(event) {
			event.preventDefault();
			video.classList.add('active');

			if (window.innerWidth > 991) {
				videoIframe.setAttribute('src', videoIframe.getAttribute('src') + '?autoplay=1');
			}
		});
	}
}
